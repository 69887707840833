import { defineStore } from 'pinia'
import { FileModel } from '~/types'

export const useOrderStore = defineStore('order', () => {
  const files = ref<Map<number, FileModel>>(new Map())
  const options = ref<Map<string, any>>(new Map())
  const copy = ref(1)
  const comment = ref()
  const scheduledOrder = ref()
  const selectFile = (value: FileModel) => {
    files.value.set(value.id, { ...value })
  }
  const setScheduledOrder = (value) => {
    scheduledOrder.value = { ...value }
  }

  const setComment = (value: string) => {
    comment.value = value?.length ? value : null
  }

  const setCopy = (value: number) => {
    copy.value = value
  }

  const selectOption = (key: string, value: any) => {
    options.value.set(key, { ...value })
  }

  const selectFiles = (value: FileModel[]) => {
    for (const fileModel of value) {
      files.value.set(fileModel.id, { ...fileModel })
    }
  }

  const unselectFile = (value: FileModel) => {
    files.value.delete(value.id)
  }
  const unselectAllFiles = () => {
    files.value.clear()
  }

  const toggleFile = (value: FileModel) => {
    const exist = files.value.has(value.id)
    exist ? unselectFile({ ...value }) : selectFile({ ...value })
  }

  const toSchedule = () => {
    return {
      files: Array.from(files.value, ([name, value]) => value.id),
      options: Array.from(options.value, ([name, value]) => value.id),
      count: copy.value
    }
  }

  const toOrder = () => {
    return {
      ...toSchedule(),
      comments: comment.value,
      delivery_at: scheduledOrder.value.start
    }
  }
  const reset = () => {
    files.value = new Map()
    options.value = new Map()
    copy.value = 1
    comment.value = null
    scheduledOrder.value = null
  }

  return {
    files,
    selectFile,
    unselectFile,
    toggleFile,
    unselectAllFiles,
    selectFiles,
    selectOption,
    setCopy,
    copy,
    options,
    setScheduledOrder,
    scheduledOrder,
    setComment,
    comment,
    toSchedule,
    toOrder,
    reset
  }
})
